import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { Grid, Container, useMediaQuery } from '@mui/material';
import { color, theme } from 'App/style';
import { SubCategoriesImages, SubCategories } from 'App/types';

import {
  Header as LayoutHeader,
  CircularLoading,
  GoBackButton,
  ComparableText,
  MerchantWrapper,
  useModal,
  Button,
  ButtonAppearance,
} from 'components';

import { AccordionItem } from 'components/Accordion';
import { CheckIcon } from 'components/Icons';
import { AccordionAppearance } from 'components/Accordion/enums';
import { Transaction } from '@ecolytiq/js-data-provider';

import { HeaderWithValue, Layout, BARS_HEIGHT, Link as LinkComponent } from 'App/components';
import {
  convertGramsToLargerUnits,
  formatCurrency,
  formatFullDate,
  useRandomComparablesByIdData,
  useRandomInsightsByIdData,
} from 'App/utils';
import { CurrencySymbol } from 'App/translations';

import { common, transactionDetails } from 'impactApp/translations/dictionary';

import { RoutePaths } from 'impactApp/routes';

import { DidYouKnow } from '../ReportDetails/submodules';

import { HowItWorksModal } from '../HomePage/submodules';

import { QuestionsStyles, Questions } from './submodules/Questions';

import { TransactionDetails } from './TransactionDetailsEdit.style';

export const TransactionDetailsEditPage = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const location = useLocation();

  const transaction: Transaction = location?.state?.transaction;
  const [answers, setAnswers] = useState(transaction?.refinementAnswers);
  const [expanded, setExpanded] = useState(false);

  const { Modal, openCallback: openModal } = useModal();
  const navigate = useNavigate();

  const isMd = useMediaQuery(theme.breakpoints.up('md'));
  const isLg = useMediaQuery(theme.breakpoints.up('lg'));

  const {
    title: pageTitle,
    submitButton,
    submittedTitle,
    submittedSubtitle,
    saveChanges,
    preferences,
    showAllTransaction,
    questionsTitle,
  } = transactionDetails;
  const { merchantPlaceholder, seoncdaryLink } = common;

  const questions = (
    <Questions
      submitButtonText={submitButton}
      saveChanges={saveChanges}
      transactionAnswers={answers}
      setAnswers={setAnswers}
      category={transaction.metaCategory}
      subCategoryValue={SubCategories[transaction?.category.value as keyof typeof SubCategories]}
      setIsSubmitted={setIsSubmitted}
      isTransactionLoop
      transactionId={transaction?.id}
      setExpanded={setExpanded}
    />
  );

  const { randomInsight } = useRandomInsightsByIdData(transaction?.id);
  const { randomComparable } = useRandomComparablesByIdData(transaction?.id, answers);
  const insight: any = randomInsight;

  const Insight = insight?.title ? (
    <DidYouKnow
      category={SubCategoriesImages[transaction?.category.value as keyof typeof SubCategoriesImages]}
      color={theme.palette.grey[400]}
      content={insight?.text}
      title={insight?.title}
    />
  ) : (
    ''
  );

  if (!transaction) {
    return (
      <Layout bgColor='light' header={<LayoutHeader button={<GoBackButton />} />}>
        <TransactionDetails.Wrapper alignItems='center' height={`calc(100vh - ${BARS_HEIGHT})`}>
          <CircularLoading />
        </TransactionDetails.Wrapper>
      </Layout>
    );
  }

  const {
    id,
    date,
    merchant,
    metaCategory,
    co2Footprint: { value: co2Value },
    amount: { value, currencyCode },
    category: { value: subCategoryValue },
  } = transaction;
  const footprint = convertGramsToLargerUnits(co2Value);

  const feedbackLoopCategory = SubCategories[subCategoryValue as keyof typeof SubCategories];
  const { REPORTS, TRANSACTIONS } = RoutePaths;

  const redirectUrlBack = `${TRANSACTIONS}/${transaction.id}`;
  const categoryURL = `${REPORTS}/${metaCategory.toLowerCase()}`;
  return (
    <Layout
      bgColor='light'
      hideContainer
      header={
        <LayoutHeader
          transparent
          title={pageTitle}
          showDesktopNavigation
          hideRightButton
          button={<GoBackButton url={redirectUrlBack} transaction={transaction} />}
        />
      }
    >
      <HowItWorksModal Modal={Modal} />

      <Container maxWidth='xl' disableGutters>
        <Grid flexDirection='column' justifyContent='center' alignItems='center'>
          <TransactionDetails.Wrapper
            item
            flexDirection='column'
            alignItems='center'
            justifyContent='center'
            width='100%'
          >
            <HeaderWithValue isLarge unitVariant='h3' value={footprint} color='primary.main' />
            {(randomComparable as any)?.text && (
              <TransactionDetails.ComparableWrapper>
                <ComparableText text={(randomComparable as any)?.text} />
              </TransactionDetails.ComparableWrapper>
            )}
            <MerchantWrapper
              price={formatCurrency(value, currencyCode as CurrencySymbol)}
              date={formatFullDate(date)}
              merchant={merchant?.name || merchantPlaceholder}
            />
          </TransactionDetails.Wrapper>
        </Grid>
      </Container>
      <TransactionDetails.PaperWrapper>
        <Container maxWidth='xl' disableGutters>
          <TransactionDetails.QuestionsWrapper>
            {isSubmitted ? (
              <Grid
                flexDirection={{ sm: 'column', lg: 'row' }}
                container
                justifyContent='flex-start'
                alignItems='flex-start'
                spacing={{ md: 4 }}
              >
                <Grid item width={isLg && insight?.title ? '50%' : '100%'}>
                  <QuestionsStyles.Content $isSubmitted={isSubmitted}>
                    <QuestionsStyles.SubmitWrapper>
                      <QuestionsStyles.SubmittedIconWrapper>
                        <CheckIcon />
                      </QuestionsStyles.SubmittedIconWrapper>
                      <QuestionsStyles.SubmittedTitle color='black' variant='h6'>
                        {submittedTitle}
                      </QuestionsStyles.SubmittedTitle>
                      <QuestionsStyles.SubmittedSubtitle color='primary.dark' variant='subtitle2'>
                        {submittedSubtitle}
                      </QuestionsStyles.SubmittedSubtitle>
                    </QuestionsStyles.SubmitWrapper>

                    <QuestionsStyles.AccordionListWrapper>
                      <AccordionItem
                        appearance={AccordionAppearance.DARK}
                        body={questions}
                        isExpanded={expanded}
                        onChange={() => setExpanded(!expanded)}
                        title={preferences.expanded}
                        titleCollapsed={preferences.collapsed}
                      />
                    </QuestionsStyles.AccordionListWrapper>
                  </QuestionsStyles.Content>
                </Grid>
                {insight?.title && (
                  <Grid item width={isLg ? '50%' : '100%'}>
                    <QuestionsStyles.InsightsWrapper>{Insight}</QuestionsStyles.InsightsWrapper>
                    <TransactionDetails.ButtonWrapper>
                      <Button
                        appearance={ButtonAppearance.SECONDARY}
                        isTransparent
                        iconRight
                        text={showAllTransaction}
                        onClick={() => navigate(categoryURL)}
                      />
                    </TransactionDetails.ButtonWrapper>
                  </Grid>
                )}
              </Grid>
            ) : (
              <>
                <QuestionsStyles.Content>
                  <QuestionsStyles.Title textAlign='center' color='common.black' variant='subtitle1'>
                    {questionsTitle}
                  </QuestionsStyles.Title>
                  <Questions
                    submitButtonText={submitButton}
                    saveChanges={saveChanges}
                    subCategoryValue={feedbackLoopCategory}
                    transactionAnswers={answers}
                    setAnswers={setAnswers}
                    setIsSubmitted={setIsSubmitted}
                    isTransactionLoop
                    transactionId={id}
                    category={metaCategory}
                  />
                </QuestionsStyles.Content>

                <QuestionsStyles.LinkWrapper>
                  <LinkComponent
                    text={seoncdaryLink}
                    textColor={isMd ? 'white' : color.orange}
                    onClick={() => openModal()}
                  />
                </QuestionsStyles.LinkWrapper>
              </>
            )}
          </TransactionDetails.QuestionsWrapper>
        </Container>
      </TransactionDetails.PaperWrapper>
    </Layout>
  );
};
