export enum Categories {
  FOOD = 'FOOD',
  TRAVEL = 'TRAVEL',
  SHOPPING = 'SHOPPING',
  LIVING = 'LIVING',
  OTHER = 'OTHER',
}

export enum CategoriesTranslation {
  FOOD = 'Food',
  TRAVEL = 'Transportation',
  SHOPPING = 'Shopping',
  LIVING = 'Living',
  OTHER = 'Uncategorized',
}

export enum SubCategories {
  'ex:transport.flight' = '2',
}

export enum SubCategoriesImages {
  'ex:food.alcohol' = 'General',
  'ex:food.beverages' = 'General',
  'ex:food.cafe' = 'Bakeries-Cafe',
  'ex:food.catering' = 'Catering',
  'ex:food.corner' = 'Convenience-Stores',
  'ex:food.groceries' = 'Groceries',
  'ex:general' = 'General',
  'ex:living.appliances' = 'General',
  'ex:living.communication' = 'General',
  'ex:living.energy' = 'Energy',
  'ex:living.glassware' = 'General',
  'ex:living.home' = 'Home',
  'ex:living.maintenance' = 'General',
  'ex:living.textiles' = 'General',
  'ex:living.tools' = 'General',
  'ex:living.water' = 'Home',
  'ex:misc.drugstore' = 'Drug-Stores',
  'ex:misc.education' = 'General',
  'ex:misc.financial' = 'General',
  'ex:misc.health' = 'General',
  'ex:misc.insurance' = 'General',
  'ex:misc.lodging' = 'Lodging-Accomodation',
  'ex:misc.miscrecreation' = 'General',
  'ex:misc.outpatientcare' = 'General',
  'ex:misc.recreation' = 'General',
  'ex:misc.services' = 'General',
  'ex:misc.social' = 'General',
  'ex:misc.streaming.audio' = 'Streaming',
  'ex:misc.streaming.video' = 'Streaming',
  'ex:misc.tobacco' = 'Convenience-Stores',
  'ex:misc.travel' = 'Travel-Agencies',
  'ex:shopping.cloth' = 'Clothes-Shopping',
  'ex:shopping.footwear' = 'General',
  'ex:shopping.furniture' = 'Furniture',
  'ex:shopping.online' = 'Online-Shopping',
  'ex:shopping.personalcare' = 'General',
  'ex:shopping.personaleffects' = 'General',
  'ex:shopping.recreationaldurables' = 'General',
  'ex:shopping.secondhandcloth' = 'General',
  'ex:shopping.stationary' = 'General',
  'ex:shopping.technology' = 'General',
  'ex:transport.carrental' = 'Car-Rental',
  'ex:transport.electricvehicle' = 'General',
  'ex:transport.emobility' = 'Service-Stations',
  'ex:transport.flight' = 'Air-Travel',
  'ex:transport.localtransport' = 'Local-Transport',
  'ex:transport.servicestations' = 'Service-Stations',
  'ex:transport.taxi' = 'Taxi',
  'ex:transport.train' = 'Train',
  'ex:transport.transportequipment' = 'General',
  'ex:transport.vehicle' = 'General',
}

export enum ComparisonValue {
  INCREASE = 'increase',
  DECREASE = 'decrease',
  CONSTANT = 'constant',
}

export enum UnitsOfMass {
  KG = 'kg',
  T = 't',
}

export enum Months {
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
}

export enum HighlightedColor {
  ORANGE = 'orange',
  BLACK = 'black',
  HIGHLIGHTED_RED = 'highlighted_red',
}

export enum AnswersIconsName {
  MEAT_BASED = 'meat-based',
  MEAT_LESS_1_DAY = 'meat-less-1-day',
  MEAT_LESS_3_DAYS = 'meat-less-up-to-3-days',
  MEAT_LESS_MORE_THAN_4_DAYS = 'meat-less-more-then-4-days',
  PESCETARIAN = 'pescetarian',
  VEGETERIAN = 'vegeterian',
  TWO_THIRDS_VEGAN = 'two-thirds-vegan',
  LOW_FOOD_CHAIN = 'low-food-chain',
  VEGAN = 'vegan',
  NORMAL = 'normal',
  REGIONAL = 'regional',
  DAILY = 'daily',
  UNTIL_THREE_TIMES = 'until-three-times',
  RARE = 'rare',
  BENZIN = 'benzin',
  DIESEL = 'diesel',
  ONE_WAY = 'one-way',
  ROUND_TRIP = 'round-trip',
  MONTHLY_TRAVEL = 'monthly-travel',
  ECONOMY_CLASS = 'economy-class',
  BUSINESS_CLASS = 'business-class',
}
