import { useState, useEffect } from 'react';

import { makeStyles } from '@mui/styles';

import { AnswersIconsName, Theme } from 'App/types';

import { TextField } from '@mui/material';

import { useLocation } from 'react-router-dom';

import { answerType } from 'impactApp/modules';

import { common } from 'impactApp/translations';

import { Modal } from 'App/components';

import { handleInfoIcon } from 'App/utils';

import { InfoIcon } from 'components';

import { renderFeedbackIcon } from '../../utils';

import { QuestionStyles } from './Question.style';

type QuestionProps = {
  id: string;
  questionType: string;
  question: string;
  answers: {
    iconName: AnswersIconsName;
    title: string;
    answerId: string;
    input: string | null;
    defaultValue: string | null;
    validation: {
      validationText: string;
      numberInput: {
        min: number;
        max: number;
      } | null;
    } | null;
  }[];
  setAnswers: (currentAnswers: answerType[]) => void;
  oldAnswers: answerType[];
  content: any;
  info: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  inFocus: {
    '& .MuiOutlinedInput-root': {
      background: theme.palette.primary.light,
    },
  },
  notInFocus: {
    '& .MuiOutlinedInput-input:not(:placeholder-shown):not(:focus)': {
      background: theme.palette.primary.light,
      color: 'black',
    },
  },
  placeholderText: {
    '& .MuiOutlinedInput-input': {
      color: 'black',
    },
  },
}));

export const Question = ({
  question,
  answers,
  setAnswers,
  oldAnswers,
  id,
  questionType,
  content,
  info,
}: QuestionProps) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [currentAnswer, setCurrentAnswer] = useState<string>();
  const [answerInput, setAnswerInput] = useState(<></>);
  const [invalidInput, setInvalidInput] = useState(false);
  const location = useLocation();

  const classes = useStyles();

  useEffect(() => {
    const answerHighlight = (questionParam: string, answerParam: string) => {
      const currentAnswers = [...oldAnswers];

      const found = currentAnswers.findIndex((element: any) => element.questionId === questionParam);

      return currentAnswer === answerParam || currentAnswers[found]?.answerId === answerParam;
    };

    const inputValue = () => {
      const currentAnswers = [...oldAnswers];
      const found = currentAnswers.findIndex((element: any) => element.questionId === id);
      if (questionType === 'text-input') return currentAnswers[found]?.input;
      const foundQuestion = content.find((element: any) => element.questionId === id);
      const foundAnswerIndex = foundQuestion.answers.findIndex(
        (element: any) => element.answerId === currentAnswers[found]?.answerId
      );
      return foundAnswerIndex + 1;
    };

    const multipleChoiceHandler = (questionParam: string, answerId: string) => {
      const currentAnswers = [...oldAnswers];

      const found = currentAnswers.findIndex((element: any) => element.questionId === questionParam);

      if (answerId !== currentAnswer) {
        const newAnswer: answerType = { questionId: questionParam, answerId, input: null };
        setCurrentAnswer(answerId);
        currentAnswers[found] = newAnswer;
      } else {
        const newAnswer: answerType = { questionId: questionParam, answerId: '', input: null };
        setCurrentAnswer('');
        currentAnswers[found] = newAnswer;
      }
      setAnswers(currentAnswers);
    };

    const inputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (questionType === 'text-input' && answers[0].validation !== null) {
        const validation = answers[0].validation;
        const input = Number(event.target.value);
        setInvalidInput(input < validation!.numberInput!.min || input > validation!.numberInput!.max);
      }

      const currentAnswers = [...oldAnswers];

      const found = currentAnswers.findIndex((element: any) => element.questionId === id);

      const answerQuestion = answers[0]?.answerId;

      const newValue = +event.target.value > 0 ? event.target.value : '1';

      const newAnswer: answerType = {
        questionId: id,
        answerId: answerQuestion || '',
        input: newValue,
      };

      setCurrentAnswer(newValue);

      currentAnswers[found] = newAnswer;

      setAnswers(currentAnswers);
    };

    const sliderHandler = (event: Event, index: any) => {
      const currentAnswers = [...oldAnswers];

      const found = currentAnswers.findIndex((element: any) => element.questionId === id);

      const foundQuestion = content.find((element: any) => element.questionId === id);
      const foundAnswer = foundQuestion.answers[index - 1];
      const newAnswer: answerType = {
        questionId: id,
        answerId: foundAnswer?.answerId || '',
        input: null,
      };

      setCurrentAnswer(foundAnswer?.answerId);

      currentAnswers[found] = newAnswer;

      setAnswers(currentAnswers);
    };

    if (questionType === 'multiple-choices') {
      setAnswerInput(
        <>
          {answers?.map((answer) => (
            <QuestionStyles.AnswerWrapper
              key={answer.title}
              isAnswer={answerHighlight(id, answer.answerId)}
              onClick={() => multipleChoiceHandler(id, answer?.answerId)}
            >
              <QuestionStyles.AnswerIconWrapper>
                {renderFeedbackIcon(answer?.iconName as AnswersIconsName, answerHighlight(id, answer.answerId))}
              </QuestionStyles.AnswerIconWrapper>
              <QuestionStyles.AnswerTitle variant='caption' color='grey.600'>
                {answer?.title}
              </QuestionStyles.AnswerTitle>
            </QuestionStyles.AnswerWrapper>
          ))}
        </>
      );
    } else if (questionType === 'text-input') {
      setAnswerInput(
        <TextField
          id='input-answer'
          placeholder={`${answers[0].defaultValue}`}
          error={invalidInput}
          className={[classes.inFocus, classes.notInFocus, classes.placeholderText].join(' ')}
          helperText={invalidInput ? answers[0].validation?.validationText : ''}
          type='number'
          value={inputValue()}
          onChange={inputHandler}
        />
      );
    } else {
      setAnswerInput(
        <QuestionStyles.Slider
          id='slider-answer'
          value={Number(inputValue())}
          valueLabelDisplay='on'
          valueLabelFormat={(value) => `${value}h`}
          onChange={sliderHandler}
          min={1}
          max={19}
          marks={[
            {
              value: 1,
              label: '1h',
            },
            {
              value: 19,
              label: '19h',
            },
          ]}
        />
      );
    }
  }, [
    questionType,
    currentAnswer,
    answers,
    id,
    oldAnswers,
    setAnswers,
    content,
    invalidInput,
    classes.inFocus,
    classes.notInFocus,
    classes.placeholderText,
  ]);

  return (
    <QuestionStyles.QuestionWrapper>
      {question && (
        <QuestionStyles.Question variant='subtitle3' color='primary.dark'>
          {question}
          {info && (
            <>
              <QuestionStyles.InfoWrapper
                onClick={() => {
                  setModalOpen(true);
                  handleInfoIcon(location.pathname, question);
                }}
              >
                <InfoIcon color='black' />
              </QuestionStyles.InfoWrapper>
              <Modal
                slug='question-modal'
                setOpen={setModalOpen}
                isOpen={isModalOpen}
                onClose={() => setModalOpen(false)}
                content={info}
                successButtonText={common.ok}
              />
            </>
          )}
        </QuestionStyles.Question>
      )}
      <QuestionStyles.AnswersWrapper>{answerInput}</QuestionStyles.AnswersWrapper>
    </QuestionStyles.QuestionWrapper>
  );
};
