import { SVGProps, useEffect, useState } from 'react';

interface RoundTripProps {
  isActive?: boolean;
  props?: SVGProps<SVGSVGElement>;
}

export const RoundTrip = ({ props, isActive }: RoundTripProps): JSX.Element => {
  const [isActiveState, setActive] = useState(isActive);

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  return (
    <svg {...props} xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 40 40' fill='none'>
      <path
        d='M8 20C8 22.1857 8.58437 24.235 9.60539 26C11.6802 29.5868 15.5583 32 20 32C23.554 32 26.7471 30.455 28.9444 28M10.6319 12.5C12.8311 9.75661 16.2104 8 20 8C26.6274 8 32 13.3726 32 20C32 20.6814 31.9432 21.3496 31.8341 22'
        stroke={isActiveState ? '#ffffff' : '#F04E39'}
        strokeWidth='1.8'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5 22.3334L8.21436 19L11.4287 22.3334'
        stroke={isActiveState ? '#ffffff' : '#F04E39'}
        strokeWidth='1.8'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M35.4287 19.0001L32.2144 22.3335L29 19.0001'
        stroke={isActiveState ? '#ffffff' : '#F04E39'}
        strokeWidth='1.8'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
