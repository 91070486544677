import { BrowserRouter } from 'react-router-dom';

import { httpClient, httpClientOauth, QueryProvider } from '@ecolytiq/js-data-provider';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { Config } from 'App/config';

import { AppsSchema, SingleApp } from './types';

import { theme, ThemeProvider } from './style';

import { workerService } from './test/api';

import { DepsProvider, TCProvider, UserProvider } from './context';

import { AutoLogout } from './components';

type AppProps = {
  readonly apps: AppsSchema;
};

const renderApp = (App: SingleApp) => <App.Component key={App.id} />;

export const App = ({ apps }: AppProps) => {
  const {
    REACT_APP_MOCK_API: isMockEnvEnabled,
    REACT_APP_ECOLYTIQ_API_GRAPHQL_URL,
    REACT_APP_ECOLYTIQ_API_AUTH_URL,
  } = Config.getAll();
  const apiHost = isMockEnvEnabled === 'true' ? '' : REACT_APP_ECOLYTIQ_API_GRAPHQL_URL;
  const apiAuthHost = isMockEnvEnabled === 'true' ? '' : REACT_APP_ECOLYTIQ_API_AUTH_URL;
  const httpClientService = httpClient(apiHost);
  const httpClientServiceOAuth = httpClientOauth(apiAuthHost);

  return (
    <DepsProvider
      httpClientServiceOAuth={httpClientServiceOAuth}
      httpClientService={httpClientService}
      workerService={workerService}
    >
      <UserProvider>
        <TCProvider>
          <QueryProvider>
            <ThemeProvider theme={theme}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <BrowserRouter>
                  {apps.map(renderApp)}
                  <AutoLogout />
                </BrowserRouter>
              </LocalizationProvider>
            </ThemeProvider>
          </QueryProvider>
        </TCProvider>
      </UserProvider>
    </DepsProvider>
  );
};
