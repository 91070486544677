export const transactionDetails = {
  title: 'Transaction Details',
  textHelper: 'emitted through your transaction',
  submitButton: 'Submit',
  feedbackLoopButton: 'Refine your transaction',
  submittedTitle: 'You refined your settings',
  submittedSubtitle: `Your transaction's CO₂e footprint changed`,
  saveChanges: 'Save changes',
  showAllTransaction: 'Show all transactions',
  questionsTitle: 'Refine your transaction',
  preferences: {
    expanded: 'Close settings',
    collapsed: 'View settings',
  },
  feedbackLoop1: {
    questions: [
      {
        questionId: '6b988e5a-5540-42d5-a0a7-d24ff054c2f9',
        type: 'multiple-choices',
        title: '1. What is your diet during a normal week?',
        answers: [
          {
            title: 'Meat-based',
            iconName: 'meat-based',
            answerId: '9d1d0a2b-597b-4608-8371-9af6e178c969',
          },
          {
            title: '1 meatless day',
            iconName: 'meat-less-1-day',
            answerId: '4a916fef-2326-4c31-b18f-25cd0ee26cbf',
          },
          {
            title: 'Up to 3 meatless days',
            iconName: 'meat-less-up-to-3-days',
            answerId: '6d264733-133b-4243-abf2-c932b45c93b0',
          },
          {
            title: '4 or more meatless days',
            iconName: 'meat-less-more-then-4-days',
            answerId: '476caf88-1714-4b8e-a945-1ceeb8cefe34',
          },
          {
            title: 'Pescetarian',
            iconName: 'pescetarian',
            answerId: 'b92e81c2-3920-4520-a515-154c3d9d0411',
          },
          {
            title: 'Vegetarian',
            iconName: 'vegeterian',
            answerId: '2c270bb0-0e1a-4b7c-846b-f4fcfe5f0fa0',
          },
          {
            title: '2/3 vegan',
            iconName: 'two-thirds-vegan',
            answerId: '17ce96c8-6a09-477e-9a3a-31161d30eb63',
          },
          {
            title: 'Low food chain',
            iconName: 'low-food-chain',
            answerId: '1a13c430-b59c-4aaa-a01b-f2cce1a7dc52',
          },
          {
            title: 'Vegan',
            iconName: 'vegan',
            answerId: '0af44297-94dd-4634-a9e3-b75b9850e029',
          },
        ],
      },
    ],
  },
  feedbackLoop2: {
    questions: [
      {
        title: 'How long is your flight? (in hours, one-way)',
        type: 'slider',
        questionId: '9ce39d4f-cb36-4e93-b896-822156c9c159',
        answers: [
          {
            answerId: 'c1583fd4-65a4-4ef8-8672-28a84f4b5074',
          },
          {
            answerId: '6755c3d6-b201-4524-b3d3-cc3f9801c17e',
          },
          {
            answerId: '85187a01-0745-4ae6-b1cc-dff2f29ab00c',
          },
          {
            answerId: '6b6124b9-344d-47bb-af6f-e82cad25e6b4',
          },
          {
            answerId: 'e8d74fcc-0e26-411a-8b08-cfddaaac65a8',
          },
          {
            answerId: 'e5a5c036-077c-4ffd-821a-71befbd3cbc4',
          },
          {
            answerId: '9d06954e-c617-4453-91c4-4509d5047a23',
          },
          {
            answerId: '2f085fa7-aceb-4e35-9da5-cb39f8a46fca',
          },
          {
            answerId: 'cac272ab-9af5-41f9-80c1-2e24447797a6',
          },
          {
            answerId: '66801fe0-8f09-4b13-be28-28ac868960cc',
          },
          {
            answerId: 'f85c4210-d359-4f7c-b878-045afd6ddbcc',
          },
          {
            answerId: '61156630-7410-4daa-8f2a-e7fdf8f27aca',
          },
          {
            answerId: 'dfdde291-7c41-4905-a709-c6a10e95238e',
          },
          {
            answerId: '467730f6-eb89-45f2-b469-71dc2d0c5595',
          },
          {
            answerId: '5451eb94-a70c-48c3-92fe-ae85da1aaef1',
          },
          {
            answerId: 'ece855ec-3024-432e-877f-0ccca2a17077',
          },
          {
            answerId: '7bf3e50e-68ee-4fc5-a5eb-4d2ab9e11731',
          },
          {
            answerId: '073cb9e4-7626-49b4-9735-159623b0948c',
          },
          {
            answerId: 'b9cd41c3-4381-4c18-b251-6f2c5743c25d',
          },
        ],
      },
      {
        title: 'Which class are you travelling in?',
        type: 'multiple-choices',
        questionId: 'cf4bd26e-c1e6-40d5-8825-e12c0246a5d5',
        info: `When you travel in a higher class, you take up more space and have higher luggage allowance than if you were in economy. This means fewer passengers on-board the plane, increasing your share of the total emissions of the flight.`,
        answers: [
          {
            title: 'Economy',
            iconName: 'economy-class',
            answerId: '152a69f0-c6ff-4f7b-bfac-fe6e92773188',
          },
          {
            title: 'Business',
            iconName: 'business-class',
            answerId: '80bf7e45-4cae-4f50-9d16-d749dd3bd9b7',
          },
        ],
      },
      {
        title: 'What type of flight is this?',
        type: 'multiple-choices',
        questionId: 'a94bc4b7-2664-43d3-97ee-1556bc6674ab',
        answers: [
          {
            title: 'One-way',
            iconName: 'one-way',
            answerId: '2815016c-a6d3-4505-82f2-02cb614fc10c',
          },
          {
            title: 'Round-trip',
            iconName: 'round-trip',
            answerId: 'a8c60936-c821-48c6-8975-9f209423d9d9',
          },
        ],
      },
      {
        title: 'For how many people did you purchase a ticket?',
        type: 'text-input',
        questionId: '4db1841f-79b3-4413-9aaa-9c5196ff96e9',
        answers: [
          {
            answerId: 'eab7ada9-0600-4a3a-994e-7cd73f02d86e',
            defaultValue: '1',
            validation: {
              numberInput: {
                min: 1,
                max: 100,
              },
              validationText: 'Your input must be between 1 and 100',
            },
          },
        ],
      },
    ],
  },
};
