import { Answer } from '@ecolytiq/js-data-provider';

import { transactionDetails } from '../../impactApp/translations';

export const getValidProfileAnswers = (answers: Answer[] | undefined): Answer[] | undefined => {
  const questions: any = transactionDetails.feedbackLoop1.questions;
  return answers?.filter((answer: Answer) => {
    return questions.find((question: any) => question.questionId === answer.questionId) !== undefined;
  });
};
