import { useState } from 'react';
import { Grid, Container, useMediaQuery } from '@mui/material';

import { Layout, Teaser, Link as LinkComponent, getValidProfileAnswers } from 'App/components';

import { color, theme } from 'App/style';
import { Header, GoBackButton, Button, useModal } from 'components';
import { CheckIcon, WomanWithGroceries } from 'components/Icons';
import { common, foodSettings } from 'impactApp/translations/dictionary';

import { useNavigate } from 'react-router-dom';

import { RoutePaths } from 'impactApp/routes/routePaths';

import { HowItWorksModal } from '../HomePage';

import { QuestionsStyles, Questions } from '../TransactionDetailsEdit/submodules/Questions';

import { FoodSettings } from './FoodSettings.style';

import { useCurrentAccountData } from './useCurrentAccountData';

export const FoodSettingsPage = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const navigate = useNavigate();
  const { REPORTS } = RoutePaths;
  const { Modal, openCallback: openModal } = useModal();

  const isMd = useMediaQuery(theme.breakpoints.up('md'));
  const { title, subtitle, submitButton, submittedTitle, submittedSubtitle, categoryButton } = foodSettings;
  const { seoncdaryLink } = common;
  const { onboardingAnswers } = useCurrentAccountData();

  return (
    <Layout
      bgColor='light'
      hideContainer
      header={<Header transparent showDesktopNavigation hideRightButton button={<GoBackButton />} />}
    >
      <HowItWorksModal Modal={Modal} />

      <Teaser hasFixedHeader isAlignCenter title={title} subtitle={subtitle} />
      <FoodSettings.PaperWrapper>
        <Container maxWidth='xl' disableGutters>
          <Grid flexDirection='column' justifyContent='center' alignItems='center'>
            <Grid item>
              <FoodSettings.QuestionsWrapper>
                {isSubmitted ? (
                  <Grid justifyContent='flex-start' alignItems='flex-start'>
                    <QuestionsStyles.Content>
                      <QuestionsStyles.SubmitWrapper>
                        <QuestionsStyles.SubmittedIconWrapper>
                          <CheckIcon />
                        </QuestionsStyles.SubmittedIconWrapper>
                        <QuestionsStyles.SubmittedTitle color='black' variant='h6'>
                          {submittedTitle}
                        </QuestionsStyles.SubmittedTitle>
                        <QuestionsStyles.SubmittedSubtitle color='black' variant='subtitle2'>
                          {submittedSubtitle}
                        </QuestionsStyles.SubmittedSubtitle>
                        <WomanWithGroceries />
                        <FoodSettings.ButtonWrapper>
                          <Button iconRight text={categoryButton} onClick={() => navigate(`${REPORTS}/food`)} />
                        </FoodSettings.ButtonWrapper>
                      </QuestionsStyles.SubmitWrapper>
                    </QuestionsStyles.Content>
                  </Grid>
                ) : (
                  <>
                    <QuestionsStyles.Content>
                      <Questions
                        submitButtonText={submitButton}
                        saveChanges=''
                        subCategoryValue='1'
                        transactionAnswers={getValidProfileAnswers(onboardingAnswers)}
                        setIsSubmitted={setIsSubmitted}
                        isTransactionLoop={false}
                        category='PROFILE'
                      />
                    </QuestionsStyles.Content>

                    <QuestionsStyles.LinkWrapper>
                      <LinkComponent
                        text={seoncdaryLink}
                        textColor={isMd ? 'white' : color.orange}
                        onClick={() => openModal()}
                      />
                    </QuestionsStyles.LinkWrapper>
                  </>
                )}
              </FoodSettings.QuestionsWrapper>
            </Grid>
          </Grid>
        </Container>
      </FoodSettings.PaperWrapper>
    </Layout>
  );
};
