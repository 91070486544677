import { SVGProps, useEffect, useState } from 'react';

interface FoodMeatLess3DaysProps {
  isActive?: boolean;
  props?: SVGProps<SVGSVGElement>;
}

export const FoodMeatLess3Days = ({ props, isActive }: FoodMeatLess3DaysProps): JSX.Element => {
  const [isActiveState, setActive] = useState(isActive);

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  return (
    <svg {...props} width='50' height='50' viewBox='0 0 52 50' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_7253_14691)'>
        <path
          d='M24.494 22.2626C24.3844 22.2626 24.2957 22.1737 24.2957 22.0642C24.2957 21.9545 24.3844 21.8657 24.494 21.8657'
          stroke={isActiveState ? '#ffffff' : '#F04E39'}
          strokeWidth='1.4'
        />
        <path
          d='M28.8758 22.2626C28.7662 22.2626 28.6775 22.1737 28.6775 22.0642C28.6775 21.9545 28.7662 21.8657 28.8758 21.8657'
          stroke={isActiveState ? '#ffffff' : '#F04E39'}
          strokeWidth='1.4'
        />
        <path
          d='M24.4937 22.2626C24.6032 22.2626 24.6919 22.1737 24.6919 22.0642C24.6919 21.9545 24.6032 21.8657 24.4937 21.8657'
          stroke={isActiveState ? '#ffffff' : '#F04E39'}
          strokeWidth='1.4'
        />
        <path
          d='M28.8762 22.2626C28.9857 22.2626 29.0745 22.1737 29.0745 22.0642C29.0745 21.9545 28.9857 21.8657 28.8762 21.8657'
          stroke={isActiveState ? '#ffffff' : '#F04E39'}
          strokeWidth='1.4'
        />
        <path
          d='M23.2593 26.6133C24.6658 25.4578 28.6113 25.4265 30.1122 26.605'
          stroke={isActiveState ? '#ffffff' : '#F04E39'}
          strokeWidth='1.4'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M25.916 27.9857V27.587'
          stroke={isActiveState ? '#ffffff' : '#F04E39'}
          strokeWidth='1.4'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M27.5096 27.9857L27.4922 27.587'
          stroke={isActiveState ? '#ffffff' : '#F04E39'}
          strokeWidth='1.4'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M31.7109 20.3291C33.2709 20.6112 35.3158 21.7077 35.6492 23.3748C34.8698 24.4545 32.9212 24.9336 30.9726 23.3748C30.7112 25.5424 29.9786 26.9616 29.0635 28.9295C28.9138 29.2502 28.6799 29.5207 28.3886 29.7101C28.0974 29.8994 27.7605 29.9999 27.4166 30H25.9548C25.6109 29.9999 25.274 29.8994 24.9828 29.7101C24.6915 29.5207 24.4576 29.2502 24.308 28.9295C23.3928 26.9616 22.6602 25.5424 22.3988 23.3748C20.4502 24.9336 18.5016 24.4545 17.7222 23.3748C18.0556 21.7077 20.1005 20.6112 21.6606 20.3291'
          stroke={isActiveState ? '#ffffff' : '#F04E39'}
          strokeWidth='1.4'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M26.7928 17.2689C26.4763 16.152 27.1251 14.99 28.242 14.6735C29.5617 14.2995 30.775 15.5727 32.1252 15.5904C33.4094 15.6072 34.4465 14.966 33.4105 13.3333C34.2653 13.4681 35.0066 14.0842 35.2582 14.9715C36.3202 18.7192 27.719 20.5368 26.7928 17.2689Z'
          stroke={isActiveState ? '#ffffff' : '#F04E39'}
          strokeWidth='1.4'
          strokeLinejoin='round'
        />
        <path
          d='M26.6331 17.2689C26.9496 16.152 26.3008 14.99 25.184 14.6735C23.8642 14.2995 22.6509 15.5727 21.3007 15.5904C20.0165 15.6072 18.9795 14.966 20.0155 13.3333C19.1606 13.4681 18.4193 14.0842 18.1678 14.9715C17.1057 18.7192 25.7069 20.5368 26.6331 17.2689Z'
          stroke={isActiveState ? '#ffffff' : '#F04E39'}
          strokeWidth='1.4'
          strokeLinejoin='round'
        />
        <line
          x1='36.0303'
          y1='10.5303'
          x2='16.0303'
          y2='30.5303'
          stroke={isActiveState ? '#ffffff' : '#F04E39'}
          strokeWidth='1.4'
        />
        <path
          d='M22.8782 36.046L21.6462 34.1741H22.8303L23.5729 35.3924L24.3223 34.1741H25.5064L24.2607 36.046L25.5645 38H24.3771L23.5729 36.6893L22.7653 38H21.5812L22.8782 36.046ZM29.1817 34.1159C29.1817 34.4285 29.087 34.6943 28.8976 34.9133C28.7083 35.1323 28.4425 35.2829 28.1003 35.365V35.3855C28.5041 35.4357 28.8098 35.5589 29.0174 35.7551C29.225 35.949 29.3288 36.2114 29.3288 36.5422C29.3288 37.0236 29.1543 37.3989 28.8052 37.6681C28.4562 37.935 27.9577 38.0684 27.3098 38.0684C26.7668 38.0684 26.2854 37.9783 25.8657 37.7981V36.8981C26.0596 36.9962 26.2729 37.076 26.5056 37.1376C26.7383 37.1992 26.9687 37.23 27.1969 37.23C27.5459 37.23 27.8037 37.1707 27.9703 37.0521C28.1368 36.9334 28.2201 36.743 28.2201 36.4806C28.2201 36.2456 28.1243 36.0791 27.9326 35.981C27.741 35.8829 27.4353 35.8338 27.0155 35.8338H26.6356V35.0228H27.0223C27.4102 35.0228 27.6931 34.9726 27.871 34.8722C28.0512 34.7695 28.1414 34.595 28.1414 34.3486C28.1414 33.9699 27.9041 33.7806 27.4296 33.7806C27.2653 33.7806 27.0976 33.8079 26.9265 33.8627C26.7577 33.9174 26.5695 34.0121 26.3619 34.1467L25.8725 33.4178C26.3288 33.0893 26.8729 32.925 27.5049 32.925C28.0227 32.925 28.4311 33.03 28.73 33.2399C29.0311 33.4498 29.1817 33.7418 29.1817 34.1159Z'
          fill={isActiveState ? '#ffffff' : '#F04E39'}
        />
      </g>
      <defs>
        <clipPath id='clip0_7253_14691'>
          <rect width='30' height='30' fill='white' transform='translate(10.5 10)' />
        </clipPath>
      </defs>
    </svg>
  );
};
