import { SVGProps, useEffect, useState } from 'react';

interface OneWayProps {
  isActive?: boolean;
  props?: SVGProps<SVGSVGElement>;
}

export const OneWay = ({ props, isActive }: OneWayProps): JSX.Element => {
  const [isActiveState, setActive] = useState(isActive);

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  return (
    <svg {...props} xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 40 40' fill='none'>
      <path
        d='M8 20C8 22.1857 8.58437 24.235 9.60539 26C11.6802 29.5868 15.5583 32 20 32C26.6274 32 32 26.6274 32 20C32 13.3726 26.6274 8 20 8C16.2104 8 12.8311 9.75661 10.6319 12.5'
        stroke={isActiveState ? '#ffffff' : '#F04E39'}
        strokeWidth='1.8'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5 22.3334L8.21436 19L11.4287 22.3334'
        stroke={isActiveState ? '#ffffff' : '#F04E39'}
        strokeWidth='1.8'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.614 22.89H16.372V17.832C16.372 17.636 16.378 17.43 16.39 17.214L15.142 18.258C15.09 18.302 15.036 18.332 14.98 18.348C14.928 18.364 14.876 18.372 14.824 18.372C14.744 18.372 14.67 18.356 14.602 18.324C14.538 18.288 14.49 18.248 14.458 18.204L13.99 17.562L16.636 15.312H17.854V22.89H19.414V24H14.614V22.89ZM22.2109 20.826L20.1889 17.844H21.6169C21.7249 17.844 21.8049 17.86 21.8569 17.892C21.9089 17.92 21.9549 17.968 21.9949 18.036L23.2849 20.094C23.3049 20.026 23.3289 19.958 23.3569 19.89C23.3889 19.822 23.4269 19.754 23.4709 19.686L24.5089 18.066C24.5569 17.99 24.6069 17.934 24.6589 17.898C24.7109 17.862 24.7749 17.844 24.8509 17.844H26.2129L24.1849 20.76L26.2969 24H24.8689C24.7609 24 24.6729 23.972 24.6049 23.916C24.5409 23.86 24.4869 23.796 24.4429 23.724L23.1349 21.582C23.0989 21.726 23.0489 21.846 22.9849 21.942L21.8329 23.724C21.7889 23.792 21.7349 23.856 21.6709 23.916C21.6069 23.972 21.5269 24 21.4309 24H20.1049L22.2109 20.826Z'
        fill={isActiveState ? '#ffffff' : '#F04E39'}
      />
    </svg>
  );
};
