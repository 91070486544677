import {
  FoodMeatBased,
  FoodMeatLess1Day,
  FoodMeatLess3Days,
  FoodMeatLessMoreThan4Days,
  FoodVegetarian,
  FoodPescetarian,
  FoodTwoThirdsVegan,
  FoodLowFoodChain,
  FoodVegan,
  FoodThreeTimes,
  FoodDaily,
  FoodNormal,
  FoodRarely,
  FoodRegional,
  Benzin,
  Diesel,
  OneWay,
  RoundTrip,
  MonthlyTravel,
  EconomyClass,
  BusinessClass,
} from 'components/Icons';
import { AnswersIconsName } from 'App/types';
import React from 'react';

const {
  MEAT_BASED,
  MEAT_LESS_1_DAY,
  MEAT_LESS_3_DAYS,
  MEAT_LESS_MORE_THAN_4_DAYS,
  PESCETARIAN,
  VEGETERIAN,
  TWO_THIRDS_VEGAN,
  LOW_FOOD_CHAIN,
  VEGAN,
  DAILY,
  RARE,
  REGIONAL,
  UNTIL_THREE_TIMES,
  NORMAL,
  BENZIN,
  DIESEL,
  ONE_WAY,
  ROUND_TRIP,
  MONTHLY_TRAVEL,
  ECONOMY_CLASS,
  BUSINESS_CLASS,
} = AnswersIconsName;

const icons = {
  [MEAT_BASED]: <FoodMeatBased />,
  [MEAT_LESS_1_DAY]: <FoodMeatLess1Day />,
  [MEAT_LESS_3_DAYS]: <FoodMeatLess3Days />,
  [MEAT_LESS_MORE_THAN_4_DAYS]: <FoodMeatLessMoreThan4Days />,
  [PESCETARIAN]: <FoodPescetarian />,
  [VEGETERIAN]: <FoodVegetarian />,
  [TWO_THIRDS_VEGAN]: <FoodTwoThirdsVegan />,
  [LOW_FOOD_CHAIN]: <FoodLowFoodChain />,
  [VEGAN]: <FoodVegan />,
  [DAILY]: <FoodDaily />,
  [RARE]: <FoodRarely />,
  [REGIONAL]: <FoodRegional />,
  [UNTIL_THREE_TIMES]: <FoodThreeTimes />,
  [NORMAL]: <FoodNormal />,
  [BENZIN]: <Benzin />,
  [DIESEL]: <Diesel />,
  [ONE_WAY]: <OneWay />,
  [ROUND_TRIP]: <RoundTrip />,
  [MONTHLY_TRAVEL]: <MonthlyTravel />,
  [ECONOMY_CLASS]: <EconomyClass />,
  [BUSINESS_CLASS]: <BusinessClass />,
};

const renderFeedbackIcon = (iconName: AnswersIconsName, $isActive: boolean) => {
  return React.cloneElement(icons[iconName] as React.ReactElement<any>, {
    isActive: $isActive,
  });
};

export { renderFeedbackIcon };
