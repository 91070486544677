import { SVGProps, useEffect, useState } from 'react';

interface FoodVeganProps {
  isActive?: boolean;
  props?: SVGProps<SVGSVGElement>;
}

export const FoodVegan = ({ props, isActive }: FoodVeganProps): JSX.Element => {
  const [isActiveState, setActive] = useState(isActive);

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  return (
    <svg {...props} xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 40 40' fill='none'>
      <g clipPath='url(#clip0_4529_76916)'>
        <path
          d='M13.3013 15.8125C17.4263 18.4375 18.5513 27.4375 18.5513 27.4375C18.5513 27.4375 20.0513 16.1875 26.4263 12.8125'
          stroke={isActiveState ? '#ffffff' : '#F04E39'}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M20.9376 19.336C20.1927 18.3986 19.8073 17.2259 19.851 16.0294C19.8947 14.8329 20.3647 13.6914 21.1761 12.811C23.0511 10.561 28.6761 10.561 28.6761 10.561C28.6761 10.561 29.0031 14.611 27.1761 17.311C25.5299 19.744 23.0563 20.638 20.9376 19.336Z'
          stroke={isActiveState ? '#ffffff' : '#F04E39'}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_4529_76916'>
          <rect width='18' height='18' fill='white' transform='translate(12 10)' />
        </clipPath>
      </defs>
    </svg>
  );
};
